<template>
  <form @input="$emit('input', formattedLetterWords)">
    <label>
      <input type="checkbox" v-model="inOrder">
      <span class="ml-10">In order (no random)</span>
    </label>

    <div v-for="(item, i) in letterWords" :key="i">
      <input type="text" v-model="item.sentence.text" placeholder="Sentence" />
      <input type="text" v-model="item.sentence.audio" placeholder="Audio" />
      <input type="text" v-model="item.answer" placeholder="Answer" />
      <input type="text" v-model="item.words" placeholder="Words: Ex: a,b,c" />
    </div>

    

    <button type="button" @click="addItem">
      + Add Item
    </button>

    
  </form>
</template>

<script>
// "letterWords": [
//     {
//       "sentence": {
//         "text": "Do you live in the river?",
//         "audio": "/class2/lesson1/task4/001_Do you live in the river.mp3"
//       },
//       "answer": "No",
//       "words": [
//         "Yes",
//         "No"
//       ]
//     },
// ],
// inOrder: true

export default {
  name: 'Type14b',
  data() {
    return {
      letterWords: [
        {
          sentence: {
            text: '',
            audio: ''
          },
          answer: '',
          words: ''
        },
      ],
      inOrder: false
    };
  },
  computed: {
    formattedLetterWords() {
     let letterWords = JSON.parse(JSON.stringify(this.letterWords)).map(lw => {
        lw.words = lw.words.replace(/[\,\/]/gi, ',').split(',').map(v => v.trim())
        return lw
      })
      return {
        letterWords, 
        inOrder: this.inOrder
      };
    },
  },
  methods: {
    addItem() {
      this.letterWords.push({
          sentence: {
            text: '',
            audio: ''
          },
          answer: '',
          words: ''
        },);
    },
  },
};
</script>
